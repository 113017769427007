import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [3,[2]],
		"/(app)/download": [11,[2]],
		"/(app)/feed": [12,[2]],
		"/(auth)/get-started": [22],
		"/(auth)/login": [23],
		"/(app)/policy/cookie": [13,[2]],
		"/(app)/policy/guidelines": [14,[2]],
		"/(app)/policy/privacy": [15,[2]],
		"/(app)/policy/terms": [16,[2]],
		"/(app)/profile": [17,[2]],
		"/(app)/recipe/create": [19,[2]],
		"/(app)/recipe/not-found": [20,[2]],
		"/(app)/recipe/[id]": [18,[2]],
		"/(app)/search": [21,[2]],
		"/(auth)/signout": [24],
		"/(auth)/signup": [25],
		"/(app)/[user=handle]": [4,[2]],
		"/(app)/[user=handle]/cookbooks": [7,[2]],
		"/(app)/[user=handle]/cookbook": [~5,[2]],
		"/(app)/[user=handle]/cookbook/[slug]": [6,[2]],
		"/(app)/[user=handle]/profile": [8,[2]],
		"/(app)/[user=handle]/recipes": [10,[2]],
		"/(app)/[user=handle]/recipe/[slug]": [9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';